import {Component, Inject, Prop, Vue} from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import { Carousel, Slide } from "vue-carousel-variable-width";
import DfStore from "@/vue/domain/store/df-store";
const DfPromotionCardComponent = () =>
  Utils.externalComponent2("df-promotion-card");

@Component({
  components: {
    DfPromotionCard: DfPromotionCardComponent,
    Carousel,
    Slide,
  },
})
export default class DfSectionPromotionsComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: String, default: null })
  type: string;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get promotions(): Array<DfPromotion> {
    return this.$store.getters.promotions.filter((promotion: DfPromotion) => {
      return Utils.getPropertyValue(promotion, Utils.PROPERTY_PROMOTION_TYPE, "LIST", { key: "FLYER" }).key === this.type;
    }).slice(0, 10);
  }

  get showGrid(): boolean {
    return this.$store.getters.promotions.filter((promotion: DfPromotion) => {
      return Utils.getPropertyValue(promotion, Utils.PROPERTY_PROMOTION_TYPE, "LIST", { key: "FLYER" }).key === this.type;
    }).length > 1 || this.type === "CATALOG" || this.type === "MAGAZINE";
  }

  get promoTitle(): string {
    switch(this.type) {
      case "FLYER":
        return "df-section-promotions.title.flyer";
      case "CATALOG":
        return "df-section-promotions.title.catalog";
      case "MAGAZINE":
        return "df-section-promotions.title.magazine";
    }
  }
}
